import App from "@cospex/client/tracker/components/App";

export default function AppPage() {
  return (
    <App
      homeRoutes={["/", "/localiza"]}
      headerSx={(isHomePage) => (isHomePage ? { mb: -13 } : {})}
    />
  );
}
